<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Addition Payroll Information</h1>
      </div>
      <AdditionPayrollForm :viewMode="true" :eventName="'updatePayroll'" @updatePayroll="updatePayroll" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AdditionPayrollForm from '@/components/financial/AdditionPayrollForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'AddAdditionPayroll',
  components: {
    AdditionPayrollForm,
  },
  mounted(){
    this.loadPayroll();
  },
  methods:{
    async loadPayroll(){
      const loginInfo = Common.getLoginInfo();
      try{
        const payrollId = this.$route.params.id;
        const payroll = await Financial.loadAdditionPayroll(this.apiUrl, payrollId, loginInfo);
        this.setCurrentAdditionPayroll(payroll);
      }catch(err){
        console.log(err);
      }
    },
    async updatePayroll(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const payrollId = this.$route.params.id;
        const payrollInfo = Object.assign({}, form);
        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        payrollInfo.employeeId = employeeId;
        const payroll = await Financial.updateAdditionPayroll(this.apiUrl, payrollId, payrollInfo, loginInfo);

      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    ...mapActions(['setCurrentAdditionPayroll']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
